import { Spinner } from "react-bootstrap";
const Loader = () => {
    return (
  
            <div className="mx-auto">
                <Spinner
                    style={{ marginRight: "1rem" }}
                    animation="grow"
                    variant="light"
                />
                <Spinner
                    style={{ marginRight: "1rem" }}
                    animation="grow"
                    variant="light"
                />
                <Spinner
                    style={{ marginRight: "1rem" }}
                    animation="grow"
                    variant="light"
                />
            </div>

    )


}

export default Loader
import React from "react";
import Navbar2 from "./Navbar2";
import "../styles/nomatch.css";

export default function NoMatch() {
  return (
    <>
      <Navbar2 />
      <div className="mainCont">
        <div>404</div>
      </div>
    </>
  );
}


import { useState, useEffect } from 'react';
export const returnToTop = function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};
function useScroll() {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // You can also add a setter function to manually set the scroll
    const goToTop = () => {

        window.scrollTo({ top: 0, behavior: 'smooth' });
        setScrolled(false);

    };

    return [scrolled, goToTop];
}

export default useScroll;





import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

function Folder({ data, history }) {
  return (
    <Link to={`/folder/${data._id}`} className="folder">
      {data.name}
      <div></div>
    </Link>
  );
}

export default withRouter(Folder);

import { useEffect } from "react";
const base64 = (input) => {
    return Buffer.from(input).toString("base64");
};

export const fetchLogin = async function (email, password, setLoading) {
    try {
        setLoading(true)
        const res = await fetch(`${process.env.REACT_APP_BE_URL}/users/login`, {
            headers: {
                Authorization: `Basic ${base64([email, password].join(":"))},`,
            }, credentials: "include"
        })
        if (res.status === 400) return 400
        if (res.status === 404) return 404
        return (res.ok)
    } catch (error) {
        console.error(error)

    } finally {
        setLoading(false)
    }
}

export   function CloseModalIfClickedOut(ref,callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback()
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        console.log("this node is out",ref)
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  export function closeModals (...modals) {
    modals.forEach((modal)=>{
        CloseModalIfClickedOut(modal.ref,modal.callback)
    })
  }
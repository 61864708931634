import React, { useEffect, useState } from "react";
import { Controlled as ControlledEditor } from "react-codemirror2";
import { useSelector, useDispatch } from "react-redux";
import { setEditorCodeAction } from "../redux/actions";

// import closebrackets.js addon to have the closing tag functionality
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/comment/comment.js";

// import "codemirror/mode/";
import "codemirror/keymap/sublime";


// main css file
import "../styles/codemirror.css";

import editorJson from "../data/editor.json"

const themeNames = editorJson["editor-themes"]
const modeNames = editorJson["editor-modes"]
// import all modes
modeNames.forEach(modeName => {
  require(`codemirror/mode/${modeName}/${modeName}`);
});
// import all css files for the different themes
themeNames.forEach(themeName => {
  require(`codemirror/theme/${themeName}.css`);
});
function Editor() {
  const snippet = useSelector((state) => state.snippet);
  const dispatch = useDispatch();
  const [snippetCorrectLang, setSnippetCorrectLang] = useState(
    snippet.editorLanguage
  );

  useEffect(() => {
    setSnippetCorrectLang(handleClikeInput(snippet.editorLanguage));
  }, [snippet.editorLanguage]);

  const handleClikeInput = (lang) => {
    switch (lang) {
      case "c":
        return "text/x-csrc";
      case "c++":
        return "text/x-c++src";
      case "java":
        return "text/x-java";
      case "c#":
        return "text/x-csharp";
      case "objective-c":
        return "text/x-objectivec";
      case "scala":
        return "text/x-scala";
      case "squirrel":
        return "text/x-squirrel";
      case "ceylon":
        return "text/x-ceylon";

      default:
        return lang;
    }
  };

  return (
    <div>
      <ControlledEditor
        className="editor"
        onBeforeChange={(editor, data, value) =>
          dispatch(setEditorCodeAction(value))
        }
        value={snippet.code}
        options={{
          keyMap: "sublime",
          lineWrapping: true,
          mode: snippetCorrectLang,
          lint: true,
          lineNumbers: true,
          theme: snippet.editorTheme,
          autoCloseBrackets: true,
        }}
      />
    </div>
  );
}

export default Editor;
